// app/javascript/controllers/kanban_controller.js

import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
    connect() {
        let container = document.getElementById('kanban-container')

        this.sortable = Sortable.create(this.element, {
            group: 'shared', // set this to allow dragging between lists
            animation: 150,
            handle: ".draggable",
            scroll: container, // Scroll the container
            scrollSensitivity: 100, // Increase the auto-scrolling sensitivity
            onStart: function (event) {
                event.item.classList.add('opacity-50');
            },
            onEnd: this.end.bind(this),

        })
    }

    end(event) {
        event.item.classList.remove('opacity-50');
        let id = event.item.dataset.id
        let new_stage = event.to.dataset.kanbanStageValue
        let originalColumn = event.from
        let item = event.item

        console.log(new_stage)
        Rails.ajax({
            url: `/leads/${id}/update_stage`,
            type: 'PATCH',
            data: `stage=${new_stage}`,
            success: response => {
                if (response.status === 'error') {
                    console.error(response.errors)
                    originalColumn.appendChild(item)
                    this.showAlert('Failed to update lead stage.')
                }
            },
            error: err => {
                console.error(err)
                originalColumn.appendChild(item)
                this.showAlert('Failed to update lead stage.')
            }
        })
    }

    showAlert(message) {
        console.log("showing alert")
        let alertHTML = `
<div class="fixed inset-x-0 top-0 flex items-end justify-right px-4 py-6 sm:p-6 justify-end z-30 pointer-events-none">
  <div data-controller="alert" data-alert-dismiss-after-value="5500" class="max-w-sm w-full shadow-lg rounded px-4 py-3 rounded relative bg-red-400 border-l-4 border-red-700 text-white pointer-events-auto">
    <div class="p-2">
      <div class="flex items-start">
        <div class="ml-3 w-0 flex-1 pt-0.5">
          <p class="text-sm leading-5 font-medium">
            ${message}
          </p>
        </div>
        <div class="ml-4 flex-shrink-0 flex">
          <button data-action="alert#close" class="inline-flex text-white focus:outline-none focus:text-gray-300 transition ease-in-out duration-150">
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
  `;

        document.body.insertAdjacentHTML('afterbegin', alertHTML);
    }
}