import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "trialAppointment", "notTrialAppointment", "trialRadio", "notTrialRadio" ]

    connect() {
        if (this.trialRadioTarget.checked) {
            this.toggle({ target: this.trialRadioTarget });
        } else if (this.notTrialRadioTarget.checked) {
            this.toggle({ target: this.notTrialRadioTarget });
        }
    }

    toggle(event) {
        if (event.target.value == "true") {
            this.trialAppointmentTarget.classList.remove('hidden');
            this.notTrialAppointmentTarget.classList.add('hidden');
            this.disableFields(this.notTrialAppointmentTarget);
            this.enableFields(this.trialAppointmentTarget);
        } else {
            this.notTrialAppointmentTarget.classList.remove('hidden');
            this.trialAppointmentTarget.classList.add('hidden');
            this.disableFields(this.trialAppointmentTarget);
            this.enableFields(this.notTrialAppointmentTarget);
        }
    }

    disableFields(element) {
        const fields = element.querySelectorAll('input, select, textarea');
        fields.forEach(field => field.disabled = true);
    }

    enableFields(element) {
        const fields = element.querySelectorAll('input, select, textarea');
        fields.forEach(field => field.disabled = false);
    }
}