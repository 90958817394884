import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "existingPerson", "newPerson", "existingPersonRadio", "newPersonRadio" ]

    connect() {
        if (this.existingPersonRadioTarget.checked) {
            this.toggle({ target: this.existingPersonRadioTarget });
        } else if (this.newPersonRadioTarget.checked) {
            this.toggle({ target: this.newPersonRadioTarget });
        }
    }

    toggle(event) {
        if (event.target.value == "existing") {
            this.existingPersonTarget.classList.remove('hidden');
            this.newPersonTarget.classList.add('hidden');
            this.disableFields(this.newPersonTarget);
            this.enableFields(this.existingPersonTarget);
        } else {
            this.newPersonTarget.classList.remove('hidden');
            this.existingPersonTarget.classList.add('hidden');
            this.disableFields(this.existingPersonTarget);
            this.enableFields(this.newPersonTarget);
        }
    }

    disableFields(element) {
        const fields = element.querySelectorAll('input, select, textarea');
        fields.forEach(field => field.disabled = true);
    }

    enableFields(element) {
        const fields = element.querySelectorAll('input, select, textarea');
        fields.forEach(field => field.disabled = false);
    }
}